import { create } from 'zustand';

import type { Edge } from '@kiroboio/reactflow';
import type { FCTNode } from '@kiroboio/fct-builder';
import type { MarketplaceAppGroupData } from '../components/builder/Provider/FCTStore/group';
import type { FlowTrace } from '../utils/flow';

interface ModalStore<
  T extends Record<string, unknown> = Record<string, unknown>,
> {
  isOpen: boolean;
  onOpen: (meta?: T) => void;
  onClose: () => void;
  meta: T;
  setMeta: (meta: T) => void;
}

const createModalStore = <T extends Record<string, unknown>>(isOpen = false) =>
  create<ModalStore<T>>((set) => ({
    isOpen,
    onOpen: (meta?: T) => set({ isOpen: true, ...(meta && { meta }) }),
    onClose: () => set({ isOpen: false }),
    meta: {} as T,
    setMeta: (meta: T) => set({ meta }),
  }));

type Draft = any;

export const isOpenSelector = (state: ModalStore) => state.isOpen;
export const metaSelector = (state: ModalStore) => state.meta;
export const onOpenSelector = (state: ModalStore) => state.onOpen;
export const onCloseSelector = (state: ModalStore) => state.onClose;
export const setMetaSelector = (state: ModalStore) => state.setMeta;

export const useChangeDraftNameModal = createModalStore<{ draft?: Draft }>();

export const useImportTokenModal = createModalStore<{
  tokenAddress?: string;
}>();

export const usePublishModal = createModalStore();
export const useImportABIModal = createModalStore();
export const useFCTCustomizeInputOutputDetailsModal = createModalStore<{
  groupId: string;
  nodeId: string;
  handleId: string;
  type: 'input' | 'output';
}>();

export const usePublishMarketplaceModal = createModalStore<{
  group?: MarketplaceAppGroupData;
}>();

export const useFCTTraceModal = createModalStore<{
  nodes: FCTNode[];
  edges: Edge[];
  name: string;
  trace?: FlowTrace;
}>();
