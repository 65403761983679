export const LP_ENDING = ' (UNI-V2)';

// export const getIsLP = (symbol: string) => symbol.endsWith(LP_ENDING);
export const getIsLP = (symbol: string) => symbol.includes(LP_ENDING);

// export const getSymbolWithoutEnding = (symbol: string) =>
//   getIsLP(symbol) ? symbol.slice(0, -LP_ENDING.length) : symbol;

export const getSymbolWithoutEnding = (symbol: string, replacer = '') =>
  getIsLP(symbol) ? symbol.replace(LP_ENDING, replacer) : symbol;
