import type { TextProps } from '@chakra-ui/react';
import { Text, Tooltip } from '@chakra-ui/react';
import { useRef } from 'react';

function isEllipsisActive(element: HTMLParagraphElement | null) {
  if (!element) return false;
  return (
    element.offsetWidth < element.scrollWidth ||
    element.offsetHeight < element.scrollHeight
  );
}

interface IProps extends TextProps {
  text: string;
  maxLength?: number;
}

export const TextOrShortened = ({ text, maxLength, ...props }: IProps) => {
  const textRef = useRef<HTMLParagraphElement>(null);

  return (
    <Tooltip
      placement="bottom-start"
      label={
        isEllipsisActive(textRef.current) || text.length > (maxLength || 0)
          ? text
          : undefined
      }
      aria-label="A tooltip"
    >
      <Text
        ref={textRef}
        {...props}
        isTruncated
        display="inline-block"
        // maxWidth="100%"
        maxWidth={maxLength ? `${maxLength}ch` : '100%'}
      >
        {text}
      </Text>
    </Tooltip>
  );
};
// export const TextOrShortened = ({ text, maxLength, ...props }: IProps) => {
//   return !maxLength || text.length <= maxLength + 3 ? (
//     <Text {...props} isTruncated>
//       {text}
//     </Text>
//   ) : (
//     <Tooltip placement="bottom-start" label={text} aria-label="A tooltip">
//       <Text {...props} maxWidth={`${maxLength}ch`} isTruncated>
//         {text}
//       </Text>
//     </Tooltip>
//   );
// };
