import { HStack, Image, Text, Tooltip } from '@chakra-ui/react';

import { useTranslations } from 'next-intl';

export const LP = () => {
  const t = useTranslations();

  return (
    <Tooltip label={t('uniswap-v2-lp-token')}>
      <HStack spacing="0px">
        <Image
          src="/icons/uniswap_logo.png"
          maxWidth="20px"
          maxHeight="20px"
          rounded="full"
          alt="(UNI-V2)"
        />

        <Text color="#f80cb4" fontSize="13px" fontWeight="700">
          LP
        </Text>
      </HStack>
    </Tooltip>
  );
};
